import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Survey from "./pages/survey/Survey";
import SurveyGroup from "./pages/surveyGroup/SurveyGroup";
import Qualification from "./pages/Qualification/Qualification";
import Invoice from "./pages/Invoice/Invoice";
import GlobalContext from "./Contexts/GlobalContext";
import Navbar from "./components/Navbar";
import EditSurveyGroup from "./pages/surveyGroup/EditSurveyGroup";
import DetailInvoice from "./pages/Invoice/DetailsInvoice";
import Profile from "./pages/Profile/Profile";
import SurveyDetails from "./pages/survey/surveyDetails/SurveyDetails";
import EncryptStart from "./Take-survey/EncryptStart";
import DIYStart from "./Take-survey/DIYStart";
import GDPR from "./Take-survey/GDPR";
import SurveyQualification from "./Take-survey/SurveyQualification";
import EndPage from "./Take-survey/EndPage";
import EndPoint from "./Take-survey/EndPoint";
import WarningBar from "./components/WarningBar";
import Payment from "./pages/Payment/Payment";
import PaymentDetails from "./pages/Payment/PaymentDetails";
import Onboarding from "./pages/Onboarding";
import AdditionalWarningBar from "./components/AdditionalWarningBar";
import { lazy, Suspense } from "react";
import Loader from "./components/Loader";
import Feasibility from "./pages/Feasibility/Feasibility";
import SecretKeyWarningBar from "./components/SecretKeyWarningBar";
import Success from "./pages/payment-checkout/Success";
import Failure from "./pages/payment-checkout/Failure";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { isLoggedIn, signIn } from "@mirats/mirats-auth";
import AccountDisabled from "./pages/AccountDisabled";
import LoginRedirect from "./pages/Auth/LoginRedirect";
import SignUpRedirect from "./pages/Auth/SignUpRedirect";
const EInvoice = lazy(() => import("./pages/EInvoice"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const AddBlockerDetected = lazy(() => import("./pages/AddBlockerDetected"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const SurveyNotLive = lazy(() => import("./Take-survey/SurveyNotLive"));
const ReconciliationVerification = lazy(() =>
  import("./pages/ReconciliationVerification/ReconciliationVerification")
);

const LoaderComp = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});
function App() {
  const ProtectedRoute = ({ children }) => {
    // console.log("protected route called");
    let { user, loading } = isLoggedIn();
    if (!user && loading) {
      signIn();
      return (
        <div className="z-[1000] flex items-center bg-white justify-center absolute h-screen w-[100vw]">
          {" "}
          <h1>Login session expired, Redirecting to login screen...</h1>
        </div>
      );
    } else {
      return children;
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContext>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/login" element={<LoginRedirect />} />
            <Route path="/sign-up" element={<SignUpRedirect />} />
            <Route
              path="/e-invoice/:id"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <EInvoice />
                </Suspense>
              }
            />
            <Route path="/success/checkout/:id" element={<Success />} />
            <Route path="/transaction-failure" element={<Failure />} />
            <Route
              path="/reconciliation-verification/:id"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <ReconciliationVerification />
                </Suspense>
              }
            />
            <Route
              path="/reconciliation-verification/:id/:status"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <ReconciliationVerification />
                </Suspense>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <TermsAndConditions />
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/refund-policy"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <RefundPolicy />
                </Suspense>
              }
            />
            <Route
              path="/DIY/v2/:surveyId/:encSupplierId/:orgId/start"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <EncryptStart />
                </Suspense>
              }
            />
            <Route
              path="/DIY/:sId/:supId/:orgId/lightningStart"
              element={
                <Suspense fallback={<LoaderComp />}>
                  {" "}
                  <DIYStart />
                </Suspense>
              }
            />
            <Route
              path="/DIY/:sId/:supId/:orgId/gdpr-consent"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <GDPR />
                </Suspense>
              }
            />
            <Route
              path="/DIY/:sId/:supId/:orgId/questions"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <SurveyQualification />
                </Suspense>
              }
            />
            <Route
              path="/7e08091a73b14e034889265e41ba796f91c766ad/:id/:status"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <EndPage />
                </Suspense>
              }
            />
            <Route
              path="/21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <EndPoint />
                </Suspense>
              }
            />
            <Route
              path="/error/:s"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <SurveyNotLive />
                </Suspense>
              }
            />
            <Route
              path="/error/add-blocker-detected"
              element={
                <Suspense fallback={<LoaderComp />}>
                  <AddBlockerDetected />
                </Suspense>
              }
            />

            <Route
              path="/onboarding"
              element={
                <ProtectedRoute>
                  <Onboarding />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/:type"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/surveys/:id/:type"
              element={
                <ProtectedRoute>
                  <SurveyDetails />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/account-disabled"
              element={
                <ProtectedRoute>
                  <AccountDisabled />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/surveys/:id"
              element={
                <ProtectedRoute>
                  <SurveyDetails />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              // path="/payment"
              element={
                <ProtectedRoute>
                  <Payment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment/account"
              element={
                <ProtectedRoute>
                  <PaymentDetails />
                </ProtectedRoute>
              }
            />
          </Routes>
          <div className={`w-full flex items-center justify-center `}>
            <div className={`w-[92%] `}>
              {/* <ProtectedRoute> */}
              <WarningBar />
              <AdditionalWarningBar />
              <SecretKeyWarningBar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/surveys"
                  element={
                    <ProtectedRoute>
                      <Survey />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/survey-groups"
                  element={
                    <ProtectedRoute>
                      <SurveyGroup />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/feasibility"
                  element={
                    <ProtectedRoute>
                      <Feasibility />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/qualification-library"
                  element={
                    <ProtectedRoute>
                      <Qualification />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/invoice-billing"
                  element={
                    <ProtectedRoute>
                      <Invoice />
                    </ProtectedRoute>
                  }
                />

                {/* edit */}
                <Route
                  path="/survey-groups/:id"
                  element={
                    <ProtectedRoute>
                      <EditSurveyGroup />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/invoice-billing/:id"
                  element={
                    <ProtectedRoute>
                      <DetailInvoice />
                    </ProtectedRoute>
                  }
                />
                {/* <Route path="*" element={<h1>Error</h1>} /> */}
              </Routes>
              {/* </ProtectedRoute> */}
            </div>
          </div>
        </BrowserRouter>
      </GlobalContext>
    </QueryClientProvider>
  );
}

export default App;
