import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { addDays, BASE_URL } from "../../Utlils";
import InvoiceMiratsIcon from "../../assets/InvoiceMiratsIcon.svg";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import charmiSign from "../../assets/CharmiSignature.png";
import { loadStripe } from "@stripe/stripe-js";
import DetailInvoiceSkeleton from "../../components/DetailInvoiceSkeleton";
const DetailInvoice = () => {
  const { id } = useParams();
  const { organization, user, setSnackbar } = useGlobalContext();
  const [singleInvoice, setSingleInvoice] = useState({});
  const [payNowLoader, setPayNowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const getSingleInvoice = async (e) => {
    setLoading(true);
    try {
      let data = await axios.get(`${BASE_URL}/invoice/one?id=${id}`);
      setSingleInvoice(data.data);
    } catch (error) {
      console.log("Unable to fetch the invoice");
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  function calcPercent(percent) {
    let totalAmount = singleInvoice?.invoiceTable?.reduce(
      (acc, curr) => (acc += curr?.totalRevenue),
      0
    );
    return Number((Number(percent) / 100) * Number(totalAmount))?.toFixed(2);
  }
  const totalAmount = Number(
    singleInvoice?.invoiceTable?.reduce(
      (acc, curr) => (acc += curr?.totalRevenue),
      0
    )
  );

  const makeStripePayment = async (e) => {
    try {
      setPayNowLoader(true);
      const strip = await loadStripe(process.env.REACT_APP_STRIPE_PK);
      const body = {
        products: [
          {
            name: `Market Research Service-${
              singleInvoice?.PO?.POPrefix + "-" + singleInvoice?.PO?.PONumber
            }`,
            quantity: 1,
            currency: singleInvoice?.currency,
            price: singleInvoice?.invoiceTable?.reduce(
              (acc, curr) => (acc += curr?.totalRevenue),
              0
            ),
          },
        ],
        token: {
          org: organization,
          email: user?.email,
          name: organization?.name,
          invoiceId: id,
        },
      };

      const session = (await axios.post(`${BASE_URL}/payment`, body)).data;
      const result = strip.redirectToCheckout({
        sessionId: session.id,
      });
      if ((await result).error) {
        console.log((await result).error);
      }
      setPayNowLoader(false);
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        msg: error?.response?.data?.message || "something went wrong!",
        severity: "error",
      });
      setPayNowLoader(false);
    }
  };
  useEffect(() => {
    if (Object?.keys(user || {})?.length) {
      getSingleInvoice();
    }
  }, [Object?.keys(user || {})?.length]);
  // console.log("singleInvoice=>", singleInvoice);
  // console.log("organization=>", organization);
  // console.log("user=>", user);
  return (
    <>
      {loading ? (
        <DetailInvoiceSkeleton />
      ) : error ? (
        <div className="h-screen flex items-center justify-center">
          <h1 className="text-red-500">
            Something went wrong. Please try again later.
          </h1>
        </div>
      ) : (
        <div className="w-[100%] mt-10 space-y-[18px] ">
          <div className="flex items-center justify-between print:hidden">
            <h2 className="text-gray-800 text-[30px] font-semibold">Invoice</h2>
            <div className="flex items-center gap-[20px]">
              <a
                href={singleInvoice?.publicURL}
                target="_blank"
                className="bg-[#0226BE] text-[#fff] py-2 px-4 rounded-lg text-[14px] font-[600]"
              >
                Download Invoice
              </a>

              {payNowLoader ? (
                <button
                  className="border border-[#0226BE] text-[#0226BE] py-2 px-4 rounded-lg text-[14px] font-[600] cursor-not-allowed"
                  disabled
                >
                  Redirecting..
                </button>
              ) : (
                singleInvoice?.paymentStats === "unpaid" && (
                  <button
                    className="bg-[#0226BE] text-[#fff] py-2 px-4 rounded-lg text-[14px] font-[600]"
                    onClick={makeStripePayment}
                  >
                    Pay Now
                  </button>
                )
              )}
            </div>
          </div>
          {/* <Divider /> */}
          <div className="shadow-invoiceCardShadow rounded-[8px] bg-[#F5FAFF] px-[18px] py-6 space-y-[18px]">
            <div className="flex justify-between items-center px-[18px]">
              <div className="flex items-start gap-5">
                <img src={InvoiceMiratsIcon} className="w-[55px] h-[55px]" />
                <div className="space-y-[2px]">
                  <h2 className="text-gray-900 text-[24px] font-semibold">
                    Mirats Quanto DIY
                  </h2>
                  <p className="text-gray-600 font-[400] text-[14px]">
                    app.miratsquanto.com
                  </p>
                  <p className="text-gray-600 font-[400] text-[14px]">
                    finance.diy@miratsinsights.com
                  </p>
                </div>
              </div>
              <div className="space-y-[2px] flex flex-col items-end">
                <h2 className="text-[#B2B7C2] text-[40px] font-semibold">
                  Invoice
                </h2>
                <p className="text-gray-600 text-[14px] font-medium">
                  {singleInvoice?.invoiceNumber || ""}
                </p>
              </div>
            </div>
            <div className="border rounded-[15px] p-[18px] bg-[#FFFFFF] space-y-6">
              <div className="flex justify-between">
                <div className="space-y-1 w-[40%]">
                  <p className="text-gray-600 text-[14px] font-medium">
                    Billed to
                  </p>
                  <div className="space-y-[2px] ">
                    <p className="text-gray-900 font-semibold text-[15px]">
                      {organization?.name || ""}
                    </p>
                    <div>
                      <p className="text-gray-600 text-[14px] font-[400]">
                        {organization?.addresses?.billingAddress?.street1 || ""}{" "}
                        {organization?.addresses?.billingAddress?.street2 || ""}
                      </p>
                    </div>
                    <p className="text-gray-600 text-[14px] font-[400]">
                      {organization?.finance?.taxIDType || ""}:
                      {organization?.finance?.taxID || ""}
                    </p>
                    <p className="text-gray-600 text-[14px] font-[400]">
                      Place of Supply:{" "}
                      {organization?.addresses?.billingAddress?.state || ""}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-[20px]">
                  <div className="space-y-1">
                    <h2 className="text-gray-600 font-medium text-[14px]">
                      PO No.
                    </h2>
                    <p className="text-gray-900 font-bold text-[14px] ">
                      {singleInvoice?.PO?.POPrefix}#
                      {singleInvoice?.PO?.PONumber}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-gray-600 font-medium text-[14px]">
                      Invoice No.
                    </h2>
                    <p className="text-gray-900 font-bold text-[14px]">
                      {singleInvoice?.invoiceNumber || ""}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-gray-600 font-medium text-[14px]">
                      Invoice date
                    </h2>
                    <p className="text-gray-900 font-bold text-[14px]">
                      {singleInvoice?.invoiceDate
                        ? new Date(
                            singleInvoice?.invoiceDate
                          )?.toLocaleDateString("en-in", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-between gap-[20px]">
                  <div className="space-y-1">
                    <h2 className="text-gray-600 font-medium text-[14px]">
                      Invoice of (USD)
                    </h2>
                    <p className="text-[#12B76A] text-xl font-bold">
                      USD{" "}
                      {organization?.addresses?.billingAddress?.country?.toLowerCase() ==
                      "india"
                        ? Number(
                            totalAmount + Number(calcPercent(18))
                          )?.toFixed(2)
                        : totalAmount?.toFixed(2)}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <h2 className="text-gray-600 font-medium text-[14px]">
                      Due date
                    </h2>
                    <p className="text-gray-900 font-bold text-[14px]">
                      {addDays(singleInvoice?.invoiceDate, 15)}
                    </p>
                  </div>
                  <h1 className="capitalize border border-red-500 text-center rounded-lg text-red-600">
                    {singleInvoice?.paymentStats}
                  </h1>

                  {/* <div className="space-y-1">
                <h2 className="text-gray-600 font-medium text-[14px]">
                  Invoice date
                </h2>
                <p className="text-gray-900 font-semibold text-[12px]">
                  01 Aug, 2023
                </p>
              </div> */}
                </div>
              </div>
              <div className="space-y-5">
                <div className="border-y px-3  py-4 flex justify-between items-center">
                  <div className="w-[220px] ">
                    <h2 className="text-gray-600 text-[14px] font-semibold">
                      Project Details
                    </h2>
                  </div>
                  <div className="w-[76px] ">
                    <h2 className="text-gray-600 text-[14px] font-semibold">
                      HSN/SAC
                    </h2>
                  </div>
                  <div className="w-[76px] ">
                    <h2 className="text-gray-600 text-[14px] font-semibold">
                      Qty
                    </h2>
                  </div>
                  <div className="w-[76px] ">
                    <h2 className="text-gray-600 text-[14px] font-semibold">
                      Rate
                    </h2>
                  </div>
                  <div className="w-[76px] ">
                    <h2 className="text-gray-600 text-[14px] font-semibold">
                      Amount
                    </h2>
                  </div>
                </div>
                <div className="border-b">
                  <div className="flex justify-between items-start px-3 py-2">
                    <div className="w-[220px] ">
                      <h2 className="text-gray-900 font-semibold text-[14px] ">
                        Market Research Service
                      </h2>
                      <p className="text-gray-600 font-[400] text-[14px]">
                        {" "}
                        {singleInvoice?.PO?.POPrefix}#
                        {singleInvoice?.PO?.PONumber}
                      </p>
                    </div>
                    <div className="w-[76px] text-gray-900 text-[14px] font-medium">
                      998371
                    </div>
                    <div className="w-[76px] text-gray-900 text-[14px] font-medium">
                      {singleInvoice?.invoiceTable?.reduce(
                        (acc, curr) => (acc += curr?.totalCompletes),
                        0
                      )}
                    </div>
                    <div className="w-[76px] text-gray-900 text-[14px] font-medium">
                      {" "}
                      {(
                        singleInvoice?.invoiceTable?.reduce(
                          (acc, curr) => (acc += curr?.totalRevenue),
                          0
                        ) /
                        singleInvoice?.invoiceTable?.reduce(
                          (acc, curr) => (acc += curr?.totalCompletes),
                          0
                        )
                      )?.toFixed(2)}
                    </div>
                    <div className="w-[76px] text-gray-900 text-[14px] font-medium">
                      USD{" "}
                      {singleInvoice?.invoiceTable
                        ?.reduce((acc, curr) => (acc += curr?.totalRevenue), 0)
                        ?.toFixed(2)}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-end space-y-3 ">
                  <div className="flex w-[40%] justify-between items-center">
                    <h2 className="text-gray-900 text-[14px] font-medium">
                      Subtotal
                    </h2>
                    <p className="text-gray-900  text-[14px] font-medium">
                      USD {totalAmount?.toFixed(2)}
                    </p>
                  </div>
                  {organization?.addresses?.billingAddress?.country?.toLowerCase() !==
                  "india" ? (
                    <div className="flex w-[40%] justify-between items-center">
                      <h2 className="text-gray-900  text-[14px] font-medium">
                        Tax (0%)
                      </h2>
                      <p className="text-gray-900  text-[14px] font-medium">
                        USD{" "}
                        {/* {singleInvoice?.invoiceTable?.reduce(
                      (acc, cur) => (acc += cur?.totalRevenue),
                      0
                    )} */}
                        0
                      </p>
                    </div>
                  ) : organization?.addresses?.billingAddress?.state?.toLowerCase() !==
                    "lucknow" ? (
                    <div className="flex w-[40%] justify-between items-center">
                      <h2 className="text-gray-900  text-[14px] font-medium">
                        IGST (18%)
                      </h2>
                      <p className="text-gray-900  text-[14px] font-medium">
                        USD {calcPercent(18)}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="flex w-[40%] justify-between items-center">
                        <h2 className="text-gray-900  text-[14px] font-medium">
                          SGST (9%)
                        </h2>
                        <p className="text-gray-900  text-[14px] font-medium">
                          USD {calcPercent(9)}
                        </p>
                      </div>
                      <div className="flex w-[40%] justify-between items-center">
                        <h2 className="text-gray-900  text-[14px] font-medium">
                          CGST (9%)
                        </h2>
                        <p className="text-gray-900  text-[14px] font-medium">
                          USD {calcPercent(9)}
                        </p>
                      </div>
                    </>
                  )}

                  <div className="w-[40%] border-b">{/* <Divider /> */}</div>
                  <div className="flex w-[40%] justify-between items-center">
                    <h2 className="text-gray-900  text-[14px] font-bold">
                      Total
                    </h2>
                    <p className="text-gray-900  text-[14px] font-bold">
                      USD{" "}
                      {organization?.addresses?.billingAddress?.country?.toLowerCase() ==
                      "india"
                        ? Number(
                            totalAmount + Number(calcPercent(18))
                          )?.toFixed(2)
                        : totalAmount?.toFixed(2)}
                    </p>
                  </div>
                  <div className="flex w-[40%] justify-between items-center">
                    <h2 className="text-gray-900  text-[14px] font-bold">
                      Total (INR)
                    </h2>
                    <p className="text-gray-900  text-[14px] font-bold">
                      ₹{" "}
                      {organization?.addresses?.billingAddress?.country?.toLowerCase() ==
                      "india"
                        ? (
                            Number(totalAmount + Number(calcPercent(18))) *
                            (singleInvoice?.INRValue || 82)
                          )?.toFixed(2)
                        : (
                            totalAmount * (singleInvoice?.INRValue || 82)
                          )?.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-[10px]">
                <h2 className="text-gray-700 font-medium text-[14px]">
                  Signature
                </h2>
                {/* <div className="w-[200px] h-[41px] flex items-center justify-start"> */}
                <img
                  src={charmiSign}
                  alt="signature"
                  className="object-cover w-[300px] -ml-[4rem]"
                />
                {/* </div> */}
                <p className="text-gray-900 font-semibold text-[14px]">
                  Thanks for the business.
                </p>
              </div>
            </div>

            <div className="space-y-2 px-[18px]">
              <h2 className="text-[#121722] font-semibold text-[15px]">
                Financial Information
              </h2>
              <div className="flex justify-between items-center">
                <div className="text-gray-600 text-[12px]">
                  <h2 className=" font-medium space-y-1">
                    MIRATS INSIGHTS LLC
                  </h2>
                  <p className="font-[400]">1309 COFFEEN AVENUE STE 1200 </p>
                  <p className="font-[400]">SHERIDAN</p>
                  <p className="font-[400]">Wyoming- 82801 USA</p>
                </div>
                <div className="text-gray-600 text-[12px]">
                  <div className="flex items-center gap-1">
                    <span>Account No. -</span>
                    <span className="font-medium">9801671632</span>
                  </div>
                  <div className="flex items-center  gap-1">
                    <span>Bank Name -</span>
                    <span className="font-medium">
                      First National Bankers Bank
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span>Branch -</span>
                    <span className="font-medium">
                      7813 Office Park Blvd, Baton Rouge, LA, 70809, USA
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span>Swift Code -</span>
                    <span className="font-medium">FRNAUS44XXXX</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span>CIN -</span>

                    <span className="font-medium">61-1995859</span>
                  </div>
                </div>
                {/* <div className="text-gray-600 text-[12px]">
              <div className="flex items-center  gap-1">
                <span>Tax ID -</span>
                <span className="font-medium"></span>
              </div>
              <div className="flex items-center gap-1">
                <span>CIN -</span>

                <span className="font-medium">61-1995859</span>
              </div>
              <div className="flex items-center gap-1">
                <span>LUT Code -</span>
                <span className="font-medium">AD090324127806Y</span>
              </div>
              <div className="flex items-center gap-1">
                <span>IFSC Code -</span>
                <span className="font-medium">ICIC0001258</span>
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <div className="page-break"></div>
          {/* <Divider /> */}

          <div className="w-[100%] mt-10 space-y-[18px] pb-5">
            <div className="shadow-invoiceCardShadow rounded-[8px] bg-[#F5FAFF] px-[18px] py-6 space-y-[18px]">
              <div className="flex justify-between items-center px-[18px]">
                <div className="flex items-start gap-5">
                  <img src={InvoiceMiratsIcon} className="w-[55px] h-[55px]" />
                  <div className="space-y-[2px]">
                    <h2 className="text-gray-900 text-[24px] font-semibold">
                      Mirats Quanto DIY
                    </h2>
                    <p className="text-gray-600 font-[400] text-[14px]">
                      app.miratsquanto.com
                    </p>
                    <p className="text-gray-600 font-[400] text-[14px]">
                      finance.diy@miratsinsights.com
                    </p>
                  </div>
                </div>
                <div className="space-y-[2px] flex flex-col items-end">
                  <h2 className="text-[#B2B7C2] text-[40px] font-semibold">
                    Invoice
                  </h2>
                  <p className="text-gray-600 text-[14px] font-medium">
                    {singleInvoice?.invoiceNumber || ""}
                  </p>
                </div>
              </div>

              <div className="bg-[#FFFFFF] p-[45px_25px] rounded-[15px] space-y-5">
                <div className="border rounded-[15px] p-[18px]  space-y-6 avoid-page-break">
                  <div className="space-y-2">
                    <h1 className="text-gray-900 text-[14px] font-bold">
                      Details Breakdown
                    </h1>
                    <div className="border-y px-3  py-4 grid grid-cols-6 justify-center items-center">
                      <div className=" col-span-2 ">
                        <h2 className="text-gray-600 text-[14px] font-semibold">
                          Project Details
                        </h2>
                      </div>
                      <div className=" text-center ">
                        <h2 className="text-gray-600 text-[14px] font-semibold">
                          HSN/SAC
                        </h2>
                      </div>
                      <div className="text-center ">
                        <h2 className="text-gray-600 text-[14px] font-semibold">
                          Qty
                        </h2>
                      </div>
                      <div className="text-center ">
                        <h2 className="text-gray-600 text-[14px] font-semibold">
                          Rate
                        </h2>
                      </div>
                      <div className="text-center ">
                        <h2 className="text-gray-600 text-[14px] font-semibold">
                          Amount
                        </h2>
                      </div>
                    </div>
                    <div className="">
                      {singleInvoice?.detailsInvoiceTable?.map((data, ind) => (
                        <div
                          className="grid grid-cols-6 justify-between items-start px-3 py-2 border-b  even:bg-gray-50 pb-[20px]"
                          key={ind}
                        >
                          <div className=" col-span-2">
                            <h2 className="text-gray-900 font-semibold text-[14px] ">
                              {data?.survey?.surveyName}
                            </h2>
                            <p className="text-gray-600 font-[400] text-[14px]">
                              #{data?.survey?.surveyNumber}
                            </p>
                          </div>
                          <div className="text-center  text-gray-900 text-[14px] font-medium">
                            998371
                          </div>

                          <div className=" col-span-3  grid grid-cols-3 border-b-2 pb-3 gap-y-4">
                            {data?.details?.map((detail, dInd) => (
                              <React.Fragment key={dInd}>
                                <div className="text-center text-gray-900 text-[14px] font-medium">
                                  {detail?.completes || 0}
                                </div>
                                <div className="text-center text-gray-900 text-[14px] font-medium">
                                  USD {detail?.cpi || 0}
                                </div>
                                <div className="text-center text-gray-900 text-[14px] font-medium">
                                  USD{" "}
                                  {(detail?.completes * detail?.cpi).toFixed(2)}
                                </div>
                              </React.Fragment>
                            ))}
                          </div>

                          <div className="col-span-3"></div>
                          <div className="col-span-3  grid grid-cols-3 py-2  pb-3">
                            <div className="text-center text-gray-900 text-[15px] font-bold">
                              {data?.details?.reduce(
                                (acc, curr) => (acc += curr?.completes),
                                0
                              )}
                            </div>
                            <div className="text-center text-gray-900 text-[15px] font-bold">
                              USD{" "}
                              {(
                                data?.details?.reduce(
                                  (acc, curr) => (acc += curr?.total),
                                  0
                                ) /
                                data?.details?.reduce(
                                  (acc, curr) => (acc += curr?.completes),
                                  0
                                )
                              )?.toFixed(2)}
                            </div>
                            <div className="text-center text-gray-900 text-[15px] font-bold">
                              USD{" "}
                              {data?.details
                                ?.reduce((acc, curr) => (acc += curr?.total), 0)
                                ?.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="py-4  rounded-[10px] border px-3 space-y-2 avoid-page-break">
                  <p className="text-gray-400 text-[14px]">Note:</p>
                  {organization?.addresses?.billingAddress?.country?.toLowerCase() ==
                  "india" ? (
                    <div className="text-gray-600 text-[14px] space-y-2">
                      <p>
                        In accordance with Circular No. 23 of 2017 dated 19 July
                        2017 issued by the Central Board of Direct Taxes,
                        Ministry of Finance, Government of India, it is noted
                        that tax deductions at source (TDS) should not be
                        applied to the GST component of the charged amount on
                        this invoice. The GST charged is in compliance with the
                        prevailing tax laws and regulations, and the breakdown
                        of the same is provided herein for transparency and
                        record-keeping purposes.
                      </p>
                      <p>
                        Please note that unless explicitly stated otherwise on
                        this invoice, the tax liabilities under the reverse
                        charge mechanism (RCM) are not applicable. Any supplies
                        subject to RCM will be clearly mentioned and detailed
                        separately to ensure compliance with GST regulations and
                        to facilitate the correct tax treatment by the recipient
                        of the supply.
                      </p>
                    </div>
                  ) : (
                    <div className="text-gray-600 text-[14px] space-y-2">
                      <p>
                        This invoice pertains to an export transaction. As per
                        the GST laws and international trade regulations,
                        exports are treated as zero-rated supplies under the
                        Goods and Services Tax (GST) framework in India.
                        Consequently, no GST is charged on the exported goods or
                        services.
                      </p>
                      <p>
                        We declare that the export of goods/services provided
                        herein is conducted under a Letter of Undertaking (LUT)
                        without payment of integrated GST, in compliance with
                        the provisions of the GST Act and rules. Alternatively,
                        if IGST is paid on the supply, we will be claiming a
                        refund as per the prescribed procedures under GST
                        regulations for exports.
                      </p>
                      <p>
                        All amounts stated in this invoice are in USD in
                        accordance with the contract/agreement terms and
                        international trade practices. Payment from the foreign
                        client should be remitted to the designated bank account
                        as per the banking details provided herein, adhering to
                        the Reserve Bank of India's guidelines on foreign
                        exchange transactions.
                      </p>
                      <p>
                        This invoice and the associated export transaction
                        comply with all applicable export regulations and
                        documentation requirements, including but not limited to
                        the Foreign Exchange Management Act (FEMA), 1999, and
                        any other regulatory provisions governing international
                        trade.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* financial details */}
              <div className="space-y-2 px-[18px]">
                <h2 className="text-[#121722] font-semibold text-[15px]">
                  Financial Information
                </h2>
                <div className="flex justify-between items-center">
                  <div className="text-gray-600 text-[12px]">
                    <h2 className=" font-medium space-y-1">
                      MIRATS INSIGHTS LLC
                    </h2>
                    <p className="font-[400]">1309 COFFEEN AVENUE STE 1200 </p>
                    <p className="font-[400]">SHERIDAN</p>
                    <p className="font-[400]">Wyoming- 82801 USA</p>
                  </div>
                  <div className="text-gray-600 text-[12px]">
                    <div className="flex items-center gap-1">
                      <span>Account No. -</span>
                      <span className="font-medium">9801671632</span>
                    </div>
                    <div className="flex items-center  gap-1">
                      <span>Bank Name -</span>
                      <span className="font-medium">
                        First National Bankers Bank
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>Branch -</span>
                      <span className="font-medium">
                        7813 Office Park Blvd, Baton Rouge, LA, 70809, USA
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>Swift Code -</span>
                      <span className="font-medium">FRNAUS44XXXX</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <span>CIN -</span>

                      <span className="font-medium">61-1995859</span>
                    </div>
                  </div>
                  {/* <div className="text-gray-600 text-[12px]">
              <div className="flex items-center  gap-1">
                <span>Tax ID -</span>
                <span className="font-medium"></span>
              </div>
              <div className="flex items-center gap-1">
                <span>CIN -</span>

                <span className="font-medium">61-1995859</span>
              </div>
              <div className="flex items-center gap-1">
                <span>LUT Code -</span>
                <span className="font-medium">AD090324127806Y</span>
              </div>
              <div className="flex items-center gap-1">
                <span>IFSC Code -</span>
                <span className="font-medium">ICIC0001258</span>
              </div>
            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailInvoice;
