import React from "react";
import Skeleton from "./Skeleton";

const DetailInvoiceSkeleton = () => {
  return (
    <div className="w-[100%] mt-10 space-y-[18px]">
      <div className="flex items-center justify-between">
        <Skeleton className="h-[40px] w-[150px] rounded-lg" />
        <div className="flex items-center gap-[20px]">
          <Skeleton className="h-[40px] w-[150px] rounded-lg" />
          <Skeleton className="h-[40px] w-[100px] rounded-lg" />
        </div>
      </div>

      <div className="shadow-invoiceCardShadow rounded-[8px] bg-[#F5FAFF] px-[18px] py-6 space-y-[18px]">
        <div className="flex justify-between items-center px-[18px]">
          <div className="flex items-start gap-5">
            <Skeleton className="w-[55px] h-[55px] rounded-full" />
            <div className="space-y-[8px]">
              <Skeleton className="h-[30px] w-[200px] rounded-lg" />
              <Skeleton className="h-[20px] w-[150px] rounded-lg" />
              <Skeleton className="h-[20px] w-[180px] rounded-lg" />
            </div>
          </div>
          <div className="space-y-[8px]">
            <Skeleton className="h-[50px] w-[150px] rounded-lg" />
            <Skeleton className="h-[20px] w-[100px] rounded-lg" />
          </div>
        </div>

        <div className="border rounded-[15px] p-[18px] bg-[#FFFFFF] space-y-6">
          <div className="flex justify-between">
            <div className="space-y-[8px] w-[40%]">
              <Skeleton className="h-[20px] w-[100px] rounded-lg" />
              <Skeleton className="h-[25px] w-[200px] rounded-lg" />
              <Skeleton className="h-[20px] w-[250px] rounded-lg" />
              <Skeleton className="h-[20px] w-[150px] rounded-lg" />
            </div>
            <div className="space-y-[8px]">
              {[1, 2, 3].map((item) => (
                <Skeleton
                  key={item}
                  className="h-[20px] w-[150px] rounded-lg mb-4"
                />
              ))}
            </div>
            <div className="space-y-[8px]">
              {[1, 2, 3].map((item) => (
                <Skeleton
                  key={item}
                  className="h-[20px] w-[150px] rounded-lg mb-4"
                />
              ))}
            </div>
          </div>

          <div className="space-y-5">
            <div className="border-y px-3 py-4">
              <div className="grid grid-cols-5 gap-4">
                {[1, 2, 3, 4, 5].map((item) => (
                  <Skeleton key={item} className="h-[20px] w-full rounded-lg" />
                ))}
              </div>
            </div>

            {[1, 2, 3].map((item) => (
              <div key={item} className="border-b py-4">
                <div className="grid grid-cols-5 gap-4">
                  {[1, 2, 3, 4, 5].map((subItem) => (
                    <Skeleton
                      key={subItem}
                      className="h-[20px] w-full rounded-lg"
                    />
                  ))}
                </div>
              </div>
            ))}

            <div className="flex flex-col items-end space-y-3">
              {[1, 2, 3, 4].map((item) => (
                <Skeleton
                  key={item}
                  className="h-[20px] w-[250px] rounded-lg"
                />
              ))}
            </div>
          </div>

          <div className="space-y-[10px]">
            <Skeleton className="h-[20px] w-[100px] rounded-lg" />
            <Skeleton className="h-[60px] w-[200px] rounded-lg" />
            <Skeleton className="h-[20px] w-[180px] rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInvoiceSkeleton;
